export default defineNuxtRouteMiddleware((to, _from) => {
  const securityStore = useSecurityStore()
  if (to.path === '/' && securityStore.permissions.SYSTEM.READ()) {
    return navigateTo('/dashboard')
  }

  if (!securityStore.isLoggedIn && to.name !== 'login') {
    return navigateTo('/login')
  } else if (securityStore.isLoggedIn && to.name === 'login') {
    return navigateTo('/dashboard')
  }
})
